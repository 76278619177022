<template>
    <div>
        <div class="page-header justify-content-between">
            <lable class="page-title">Add New Member</lable>  
        </div>
        <div class="create_sec">
          <div class="create_cnt">
              <div class="create_cnt_list">
                <h6>Member Details -</h6>
                <ul>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">First Name *</label>
                      <input type="text" class="form_control">
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Secound Name *</label>
                      <input type="text" class="form_control">
                    </div>
                  </li>
                  <li>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Profile *</label>
                      <multiselect
                            v-model="categorySelected" 
                            :show-labels="false" 
                            :options="categoryOptions">
                        </multiselect>
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Admin Type *</label>
                      <multiselect
                            v-model="SubCategorySelected" 
                            :show-labels="false" 
                            :options="SubCategorygOptions">
                        </multiselect>
                    </div>
                  <li>
                   <div class="d-flex align-items-center">
                      <label class="form_label">Location *</label> 
                        <multiselect
                            v-model="prioritySelected" 
                            :show-labels="false" 
                            :options="priorityOptions">
                        </multiselect>
                    </div> 
                    <div class="d-flex align-items-center">
                      <label class="form_label">Email ID *</label>
                      <input type="email" class="form_control">
                    </div> 
                  </li>
                  <li class="recurrence">
                    <div class="d-flex align-items-center">
                      <label class="form_label">Mobile # *</label>
                      <input type="text" class="form_control">
                    </div>
                    <div class="d-flex align-items-center">
                      <label class="form_label">Alternative *</label>
                      <input type="text" class="form_control">
                    </div>
                  </li> 
                </ul>
              </div>
          </div>  
          
        </div>
        <div class="create_actions">
              <button>CANCEL</button>
              <button>SAVE</button>
          </div>
    </div>
</template>

<script> 
import Multiselect from "vue-multiselect-inv"; 
  export default {
     components: {
        Multiselect,  
    },
    data() {
      return { 
         
        prioritySelected:'High',
        priorityOptions: ['High ', 'Low', 'Medium'], 
          
        SubCategorySelected: 'Internal meeting',   
        SubCategorygOptions: ['Internal meeting ', 'External meeting',], 
        categorySelected: 'My Visit',   
        categoryOptions: ['My Visit ', 'Client Visit'], 
      }
    },
 
  }
</script>